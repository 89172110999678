import { del, get, post, postFormData, put } from "./api_helper";

const api = {
  login(data) {
    return post("auth/login", data)
  },
  dashboard(data) {
    return get("/dashboard", data)
  },
  dashboardContact(data) {
    return get("/dashboard/contact", data)
  },
  dashboardVisitor(data) {
    return get("/dashboard/visitor", data)
  },
  settings(data) {
    return get("/settings", data)
  },
  updateSettings(data) {
    return postFormData("/settings", data)
  },

  // Category
  categories(data) {
    return data.params?.id
      ? get(`/categories/${data.params.id}`, data)
      : get("/categories", data)
  },
  createCategory(data) {
    return post("/categories", data)
  },
  updateCategory(id, data) {
    return post(`/categories/${id}`, data)
  },
  deleteCategory(id, data) {
    return del(`/categories/${id}`, data)
  },
  categorySelect(data) {
    return get(`/categories/select`, data)
  },

  categoryPage(id) {
    return get(`/category-pages/${id}`)
  },
  setCategoryPage(data) {
    return post("/category-pages", data)
  },

  // Select
  supplierSelect() {
    return get(`/products/supplier-select`)
  },
  scrapePriceSupplier(data) {
    return get("/scrape-price-suppliers", data)
  },

  // Brand
  brandSelect() {
    return get(`/products/brand-select`)
  },
  brand(data) {
    return data.params?.id
      ? get(`/brands/${data.params.id}`, data)
      : get("/brands", data)
  },
  createBrand(data) {
    return post("/brands", data)
  },
  updateBrand(id, data) {
    return post(`/brands/${id}`, data)
  },
  deleteBrand(id, data) {
    return del(`/brands/${id}`, data)
  },
  brandList(data) {
    return get("/products/brand-list", data)
  },

  // Product
  syncProduct(data) {
    return get("/products/sync", data)
  },
  syncProductData(data) {
    return post("/products/sync-product-data-to-server", data)
  },
  pushProductAE(data) {
    return post("/products/push-product-to-server", data)
  },
  importProduct(data) {
    return postFormData("/products/import-product", data)
  },
  importBuyPriceProduct(data) {
    return postFormData("/products/import-buy-price", data)
  },
  updatePriceUpdateCheapest(data) {
    return post("/products/price-update-cheapest", data)
  },
  updatePriceUpdateBySupplier(data) {
    return post("/products/price-update-by-supplier", data)
  },
  updateBuyPriceUpdateCheapest(data) {
    return post("/products/buy-price-update-cheapest", data)
  },
  deleteBuyPriceImport(id, data) {
    return del(`/products/delete-buy-price-import/${id}`, data)
  },
  updateBuyPriceUpdateBySupplier(data) {
    return post("/products/buy-price-update-by-supplier", data)
  },
  updateTradePriceUpdateCheapest(data) {
    return post("/products/trade-price-update-cheapest", data)
  },
  updateTradePriceUpdateBySupplier(data) {
    return post("/products/trade-price-update-by-supplier", data)
  },
  updateAdditional(id, data) {
    return post(`/products/update-additional/${id}`, data)
  },
  autoSetPrice(data) {
    return post(`/products/auto-set-price`, data)
  },
  productSelect(data) {
    return get(`/products/product-select`, data)
  },
  product(data) {
    return data.params?.id
      ? get(`/products/show/${data.params.id}`, data)
      : get("/products", data)
  },
  createProduct(data) {
    return postFormData("/products", data)
  },
  updateProduct(id, data) {
    return postFormData(`/products/${id}`, data)
  },
  updateFieldProduct(id, data) {
    return post(`/products/update-field/${id}`, data)
  },
  updateKitProduct(id, data) {
    return post(`/products/update-kit/${id}`, data)
  },
  deleteProduct(id, data) {
    return del(`/products/${id}`, data)
  },
  relatedProduct(data) {
    return get(`/products/related-products`, data)
  },
  updateRelatedProduct(id, data) {
    return post(`/products/update-related-product/${id}`, data)
  },
  crossSellProduct(data) {
    return get(`/products/cross-sell-products`, data)
  },
  updateCrossSellProduct(id, data) {
    return post(`/products/update-cross-sell-product/${id}`, data)
  },
  productLogs(data) {
    return get(`/products/logs`, data)
  },
  upSellProduct(data) {
    return get(`/products/up-sell-products`, data)
  },
  updateUpSellProduct(id, data) {
    return post(`/products/update-up-sell-product/${id}`, data)
  },
  createSupplierBuyingPrice(data) {
    return post(`/products/supplier-buy-price`, data)
  },
  updateSupplierBuyingPrice(id, data) {
    return post(`/products/supplier-buy-price/${id}`, data)
  },

  // Price Level
  priceLevel(data) {
    return get("/price-level", data)
  },
  showPriceLevel(data) {
    return get("/price-level/show", data)
  },
  createPriceLevel(data) {
    return post("/price-level", data)
  },
  updatePriceLevel(id, data) {
    return post(`/price-level/${id}`, data)
  },
  deletePriceLevel(id, data) {
    return del(`/price-level/${id}`, data)
  },

  // Supplier
  supplier(data) {
    return data.params?.id
      ? get(`/suppliers/${data.params.id}`, data)
      : get("/suppliers", data)
  },
  createSupplier(data) {
    return post("/suppliers", data)
  },
  updateSupplier(id, data) {
    return post(`/suppliers/${id}`, data)
  },
  deleteSupplier(id, data) {
    return del(`/suppliers/${id}`, data)
  },

  // Supplier price
  supplierPrice(data) {
    return data.params?.id
      ? get(`/suppliers/price/${data.params.id}`, data)
      : get("/suppliers/price", data)
  },
  createSupplierPrice(data) {
    return post("/suppliers/price", data)
  },
  updateSupplierPrice(id, data) {
    return post(`/suppliers/price/${id}`, data)
  },
  deleteSupplierPrice(id, data) {
    return del(`/suppliers/price/${id}`, data)
  },

  // Supplier Buy price
  supplierBuyPrice(data) {
    return data.params?.id
      ? get(`/suppliers/buy-price/${data.params.id}`, data)
      : get("/suppliers/buy-price", data)
  },
  createSupplierBuyPrice(data) {
    return post("/suppliers/buy-price", data)
  },
  updateSupplierBuyPrice(id, data) {
    return post(`/suppliers/buy-price/${id}`, data)
  },
  deleteSupplierBuyPrice(id, data) {
    return del(`/suppliers/buy-price/${id}`, data)
  },
  selectSupplierBuyPrice(data) {
    return get(`/products/select-supplier-buy-price`, data)
  },

  // Supplier Trade price
  supplierTradePrice(data) {
    return data.params?.id
      ? get(`/suppliers/trade-price/${data.params.id}`, data)
      : get("/suppliers/trade-price", data)
  },
  createSupplierTradePrice(data) {
    return post("/suppliers/trade-price", data)
  },
  updateSupplierTradePrice(id, data) {
    return post(`/suppliers/trade-price/${id}`, data)
  },
  deleteSupplierTradePrice(id, data) {
    return del(`/suppliers/trade-price/${id}`, data)
  },

  // User
  user(data) {
    return data.params?.id
      ? get(`/users/${data.params.id}`, data)
      : get("/users", data)
  },
  createUser(data) {
    return postFormData("/users", data)
  },
  updateUser(id, data) {
    return postFormData(`/users/${id}`, data)
  },
  deleteUser(id, data) {
    return del(`/users/${id}`, data)
  },

  // Scrape RRP Price
  startScrapePrice(data) {
    return post("/start-scrape-price", data)
  },
  syncPriceUpdateToServer(data) {
    return post("/products/sync-scrape-price-to-server", data)
  },
  runStockUpdate(data) {
    return post("/products/run-stock-update", data)
  },
  sendStockUpdate(data) {
    return post("/products/stock-update", data)
  },
  scrapePrice(data) {
    return data.params?.id
      ? get(`/scrape-price/${data.params.id}`, data)
      : get("/scrape-price", data)
  },
  sendPriceUpdate(data) {
    return post("/price-update", data)
  },
  sendTradePriceUpdate(data) {
    return post("/trade-price-update", data)
  },
  updateScrapePrice(id, data) {
    return post(`/scrape-price/${id}`, data)
  },

  // Scrape Buy Price
  startScrapeBuyPrice(data) {
    return post("/start-scrape-buy-price", data)
  },
  syncBuyPriceUpdateToServer(data) {
    return post("/products/sync-scrape-buy-price-to-server", data)
  },
  sendBuyPriceUpdate(data) {
    return post("/buy-price-update", data)
  },
  scrapeBuyPrice(data) {
    return data.params?.id
      ? get(`/scrape-buy-price/${data.params.id}`, data)
      : get("/scrape-buy-price", data)
  },
  scrapeBuyPriceSupplier(data) {
    return get("/scrape-buy-price-suppliers", data)
  },
  updateScrapeBuyPrice(id, data) {
    return post(`/scrape-buy-price/${id}`, data)
  },

  // Scrape Trade
  startScrapeTrade(data) {
    return post("/scrape-trade", data)
  },
  syncTradePriceUpdateToServer(data) {
    return post("/products/sync-scrape-trade-price-to-server", data)
  },
  scrapeTrade(data) {
    return data.params?.id
      ? get(`/scrape-trades/${data.params.id}`, data)
      : get("/scrape-trades", data)
  },
  scrapeTradeSupplier(data) {
    return get("/scrape-trade-suppliers", data)
  },
  createScrapeTrade(data) {
    return postFormData("/scrape-trades", data)
  },
  updateScrapeTrade(id, data) {
    return post(`/scrape-trades/${id}`, data)
  },
  deleteScrapeTrade(id, data) {
    return del(`/scrape-trades/${id}`, data)
  },
}

export default api;