import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "components/Common/Breadcrumb"
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { showToast } from 'helpers/utils';

const StockUpdate = () => {
  const [errors, setErrors] = useState([])

  const { register, setValue, handleSubmit, reset, formState: { formErrors } } = useForm();

  const { data, refetch: getData, isLoading } = useQuery(['settings'], () => api.settings(), {
    onSuccess: (res) => {
      setValue(
        "leader_system_stock_url",
        res.data.find(setting => setting.key == "leader_system_stock_url").value
      )
      setValue(
        "alloys_stock_url",
        res.data.find(setting => setting.key == "alloys_stock_url").value
      )
    },
    refetchOnWindowFocus: false,
  });

  const {
    mutate,
    isLoading: submitLoading,
    isSuccess,
  } = useMutation({
    mutationFn: async params => {
      return await api.runStockUpdate(params)
    },
    onSuccess: () => {
      showToast("Successfully run stock update.")
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Stock Update" />
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit(mutate)}>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <label>Leader System</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="URL"
                        {...register("leader_system_stock_url", {
                          required: true,
                        })}
                      />
                      {formErrors?.leader_system_stock_url && (
                        <small className="text-danger">
                          This field is required
                        </small>
                      )}
                      {errors.leader_system_stock_url && (
                        <span className="form-text text-danger">
                          {errors.leader_system_stock_url[0]}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <label>Alloys</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="URL"
                        {...register("alloys_stock_url", {
                          required: true,
                        })}
                      />
                      {formErrors?.alloys_stock_url && (
                        <small className="text-danger">
                          This field is required
                        </small>
                      )}
                      {errors.alloys_stock_url && (
                        <span className="form-text text-danger">
                          {errors.alloys_stock_url[0]}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>

                <button
                  type="submit"
                  className="btn btn-primary w-md"
                  disabled={submitLoading}
                >
                  Save & Run Stock Update
                </button>
              </form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default React.memo(StockUpdate);