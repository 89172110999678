import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { showToast } from 'helpers/utils';
import React, { useState } from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown } from 'reactstrap';

const Price = ({ data, updateField }) => {
  const [priceLevel0, setPriceLevel0] = useState(25)
  const [priceLevel1, setPriceLevel1] = useState(20)
  const [priceLevel2, setPriceLevel2] = useState(18)
  const [priceLevel3, setPriceLevel3] = useState(16)
  const [priceLevel4, setPriceLevel4] = useState(14)
  const [priceLevel5, setPriceLevel5] = useState(12)

  // Do not delete
  const { data: priceLevel, isLoading: loadingPriceLevel } = useQuery(
    ["price-level"],
    () =>
      api.showPriceLevel({
        params: {
          brand: data?.brand,
          category: data?.category,
        },
      }),
    {
      onSuccess: res => {
        setPriceLevel0(res?.data?.price ?? 25)
        setPriceLevel1(res?.data?.price_1 ?? 20)
        setPriceLevel2(res?.data?.price_2 ?? 18)
        setPriceLevel3(res?.data?.price_3 ?? 16)
        setPriceLevel4(res?.data?.price_4 ?? 14)
        setPriceLevel5(res?.data?.price_5 ?? 12)
      },
      refetchOnWindowFocus: true,
    }
  )

  const updateAllPriceByPercentage = type => {
    switch (type) {
      case 0:
        updateField({
          price: data?.buy_price * (priceLevel0 / 100 + 1),
        })
        break
      case 1:
        updateField({
          price_1: data?.buy_price * (priceLevel1 / 100 + 1),
        })
        break
      case 2:
        updateField({
          price_2: data?.buy_price * (priceLevel2 / 100 + 1),
        })
        break
      case 3:
        updateField({
          price_3: data?.buy_price * (priceLevel3 / 100 + 1),
        })
        break
      case 4:
        updateField({
          price_4: data?.buy_price * (priceLevel4 / 100 + 1),
        })
        break
      case 5:
        updateField({
          price_5: data?.buy_price * (priceLevel5 / 100 + 1),
        })
        break
      case "all":
        updateField({
          price: data?.buy_price * (priceLevel0 / 100 + 1),
          price_1: data?.buy_price * (priceLevel1 / 100 + 1),
          price_2: data?.buy_price * (priceLevel2 / 100 + 1),
          price_3: data?.buy_price * (priceLevel3 / 100 + 1),
          price_4: data?.buy_price * (priceLevel4 / 100 + 1),
          price_5: data?.buy_price * (priceLevel5 / 100 + 1),
        })
        break
      default:
        break
    }
  }

  const { mutate: priceUpdate, isLoading: priceUpdateLoading } = useMutation(
    params =>
      api.syncProductData({
        id: [data?._id],
      }),
    {
      onSuccess: res => {
        showToast("Successfully update price")
        if (!res.status) {
          showToast("Failed to update price", "error")
        }
      },
      onError: err => {
        showToast("Failed to update price", "error")
      },
    }
  )

  const IndividualPrice = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-8">
            <h4
              className="text-center mb-3 text-white w-25 p-2 position-relative"
              style={{ backgroundColor: "#132747", left: "60%" }}
            >
              System Price
            </h4>
          </div>
          <div className="col-md-4 text-end">
            <button
              className="btn btn-primary p-2 mx-2"
              onClick={priceUpdate}
              disabled={priceUpdateLoading}
            >
              Update to AE
            </button>
            <button
              className="btn btn-primary p-2 mx-2"
              // onClick={priceUpdate}
              disabled={priceUpdateLoading}
            >
              Update to SM
            </button>
            {/* <UncontrolledDropdown
              className="dropdown d-inline-block me-1 w-100"
              disabled={priceUpdateLoading}
            >
              <DropdownToggle
                type="menu"
                className="btn btn-primary w-100"
                color="primary"
                caret={true}
                id="dropdownMenuButton1"
              >
                Update Product
              </DropdownToggle>
              <DropdownMenu>
                <li>
                  <DropdownItem onClick={() => priceUpdate()}>
                    Alarm Expert
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem href="#">Surveillance Master</DropdownItem>
                </li>
              </DropdownMenu>
            </UncontrolledDropdown> */}
          </div>
        </div>
        <Table className="table mb-3 table-bordered">
          <thead className="text-white" style={{ backgroundColor: "#132747" }}>
            <tr className="text-center">
              <th>Buy Price</th>
              <th>Price 0 (RRP)</th>
              <th>Trade Price (Trade)</th>
              <th>Silver</th>
              <th>Gold</th>
              <th>Platinum</th>
              <th>Diamond</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <th>Exc: ${Number(data.buy_price / 1.1).toFixed(2)}</th>
              <th>
                Exc: ${Number(data.price / 1.1).toFixed(2)}
                <div className="text-success">
                  Profit: $
                  {Number(data.price / 1.1 - data.buy_price / 1.1).toFixed(2)}
                </div>
                <div className="text-warning">
                  {priceLevel0}%: $
                  {Number(
                    (data.buy_price / 1.1) * (priceLevel0 / 100 + 1)
                  ).toFixed(2)}
                </div>
              </th>
              <th>
                Exc: ${Number(data.price_1 / 1.1).toFixed(2)}
                <div className="text-success">
                  Profit: $
                  {Number(data.price_1 / 1.1 - data.buy_price / 1.1).toFixed(2)}
                </div>
                <div className="text-warning">
                  {priceLevel1}%: $
                  {Number(
                    (data.buy_price / 1.1) * (priceLevel1 / 100 + 1)
                  ).toFixed(2)}
                </div>
              </th>
              <th>
                Exc: ${Number(data.price_2 / 1.1).toFixed(2)}
                <div className="text-success">
                  Profit: $
                  {Number(data.price_2 / 1.1 - data.buy_price / 1.1).toFixed(2)}
                </div>
                <div className="text-warning">
                  {priceLevel2}%: $
                  {Number(
                    (data.buy_price / 1.1) * (priceLevel2 / 100 + 1)
                  ).toFixed(2)}
                </div>
              </th>
              <th>
                Exc: ${Number(data.price_3 / 1.1).toFixed(2)}
                <div className="text-success">
                  Profit: $
                  {Number(data.price_3 / 1.1 - data.buy_price / 1.1).toFixed(2)}
                </div>
                <div className="text-warning">
                  {priceLevel3}%: $
                  {Number(
                    (data.buy_price / 1.1) * (priceLevel3 / 100 + 1)
                  ).toFixed(2)}
                </div>
              </th>
              <th>
                Exc: ${Number(data.price_4 / 1.1).toFixed(2)}
                <div className="text-success">
                  Profit: $
                  {Number(data.price_4 / 1.1 - data.buy_price / 1.1).toFixed(2)}
                </div>
                <div className="text-warning">
                  {priceLevel4}%: $
                  {Number(
                    (data.buy_price / 1.1) * (priceLevel4 / 100 + 1)
                  ).toFixed(2)}
                </div>
              </th>
              <th>
                Exc: ${Number(data.price_5 / 1.1).toFixed(2)}
                <div className="text-success">
                  Profit: $
                  {Number(data.price_5 / 1.1 - data.buy_price / 1.1).toFixed(2)}
                </div>
                <div className="text-warning">
                  {priceLevel5}%: $
                  {Number(
                    (data.buy_price / 1.1) * (priceLevel5 / 100 + 1)
                  ).toFixed(2)}
                </div>
              </th>
            </tr>
            <tr className="text-center">
              <th>Inc: ${Number(data.buy_price).toFixed(2)}</th>
              <th>
                Inc: ${Number(data.price).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(data.price - data.buy_price).toFixed(2)}
                </div>
                <div className="text-warning">
                  {priceLevel0}%: $
                  {Number(data.buy_price * (priceLevel0 / 100 + 1)).toFixed(2)}
                </div>
              </th>
              <th>
                Inc: ${Number(data.price_1).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(data.price_1 - data.buy_price).toFixed(2)}
                </div>
                <div className="text-warning">
                  {priceLevel1}%: $
                  {Number(data.buy_price * (priceLevel1 / 100 + 1)).toFixed(2)}
                </div>
              </th>
              <th>
                Inc: ${Number(data.price_2).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(data.price_2 - data.buy_price).toFixed(2)}
                </div>
                <div className="text-warning">
                  {priceLevel2}%: $
                  {Number(data.buy_price * (priceLevel2 / 100 + 1)).toFixed(2)}
                </div>
              </th>
              <th>
                Inc: ${Number(data.price_3).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(data.price_3 - data.buy_price).toFixed(2)}
                </div>
                <div className="text-warning">
                  {priceLevel3}%: $
                  {Number(data.buy_price * (priceLevel3 / 100 + 1)).toFixed(2)}
                </div>
              </th>
              <th>
                Inc: ${Number(data.price_4).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(data.price_4 - data.buy_price).toFixed(2)}
                </div>
                <div className="text-warning">
                  {priceLevel4}%: $
                  {Number(data.buy_price * (priceLevel4 / 100 + 1)).toFixed(2)}
                </div>
              </th>
              <th>
                Inc: ${Number(data.price_5).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(data.price_5 - data.buy_price).toFixed(2)}
                </div>
                <div className="text-warning">
                  {priceLevel5}%: $
                  {Number(data.buy_price * (priceLevel5 / 100 + 1)).toFixed(2)}
                </div>
              </th>
            </tr>
            <tr className="text-center">
              <th></th>
              <th>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => updateAllPriceByPercentage(0)}
                >
                  Set Price to {priceLevel0}%
                </button>
              </th>
              <th>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => updateAllPriceByPercentage(1)}
                >
                  Set Price to {priceLevel1}%
                </button>
              </th>
              <th>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => updateAllPriceByPercentage(2)}
                >
                  Set Price to {priceLevel2}%
                </button>
              </th>
              <th>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => updateAllPriceByPercentage(3)}
                >
                  Set Price to {priceLevel3}%
                </button>
              </th>
              <th>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => updateAllPriceByPercentage(4)}
                >
                  Set Price to {priceLevel4}%
                </button>
              </th>
              <th>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => updateAllPriceByPercentage(5)}
                >
                  Set Price to {priceLevel5}%
                </button>
              </th>
            </tr>
          </tbody>
        </Table>
        <button
          className="btn btn-primary w-100 mb-5"
          onClick={() => updateAllPriceByPercentage("all")}
        >
          Update All Price by Percentage
        </button>
      </>
    )
  }

  const KitPrice = () => {
    const buy_price = data.kits.reduce((prev, next) => prev + (next.product.buy_price * next.qty), 0)
    const price = data.kits.reduce((prev, next) => prev + (next.product.price * next.qty), 0)
    const price_1 = data.kits.reduce((prev, next) => prev + (next.product.price_1 * next.qty), 0)
    const price_2 = data.kits.reduce((prev, next) => prev + (next.product.price_2 * next.qty), 0)
    const price_3 = data.kits.reduce((prev, next) => prev + (next.product.price_3 * next.qty), 0)
    const price_4 = data.kits.reduce((prev, next) => prev + (next.product.price_4 * next.qty), 0)
    const price_5 = data.kits.reduce((prev, next) => prev + (next.product.price_5 * next.qty), 0)
    return (
      <>
        <div className="row">
          <div className="col-md-8">
            <h4
              className="text-center mb-3 text-white w-25 p-2 position-relative rounded"
              style={{ backgroundColor: "#132747", left: "60%" }}
            >
              System Price
            </h4>
          </div>
          <div className="col-md-4 text-end">
            <button
              className="btn btn-primary p-2 mx-2"
              onClick={priceUpdate}
              disabled={priceUpdateLoading}
            >
              Update to AE
            </button>
            <button
              className="btn btn-primary p-2 mx-2"
              // onClick={priceUpdate}
              disabled={priceUpdateLoading}
            >
              Update to SM
            </button>
            {/* <UncontrolledDropdown
              className="dropdown d-inline-block me-1 w-100"
              disabled={priceUpdateLoading}
            >
              <DropdownToggle
                type="menu"
                className="btn btn-primary w-100"
                color="primary"
                caret={true}
                id="dropdownMenuButton1"
              >
                Update Product
              </DropdownToggle>
              <DropdownMenu>
                <li>
                  <DropdownItem onClick={() => priceUpdate()}>
                    Alarm Expert
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem href="#">Surveillance Master</DropdownItem>
                </li>
              </DropdownMenu>
            </UncontrolledDropdown> */}
          </div>
        </div>
        <Table className="table mb-3 table-bordered">
          <thead className="text-white" style={{ backgroundColor: "#132747" }}>
            <tr className="text-center">
              <th>Buy Price</th>
              <th>Price 0 (RRP)</th>
              <th>Trade Price (Trade)</th>
              <th>Silver</th>
              <th>Gold</th>
              <th>Platinum</th>
              <th>Diamond</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <th>Exc: ${Number(buy_price / 1.1).toFixed(2)}</th>
              <th>
                Exc: ${Number(price / 1.1).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(price / 1.1 - buy_price / 1.1).toFixed(2)}
                </div>
              </th>
              <th>
                Exc: ${Number(price_1 / 1.1).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(price_1 / 1.1 - buy_price / 1.1).toFixed(2)}
                </div>
              </th>
              <th>
                Exc: ${Number(price_2 / 1.1).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(price_2 / 1.1 - buy_price / 1.1).toFixed(2)}
                </div>
              </th>
              <th>
                Exc: ${Number(price_3 / 1.1).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(price_3 / 1.1 - buy_price / 1.1).toFixed(2)}
                </div>
              </th>
              <th>
                Exc: ${Number(price_4 / 1.1).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(price_4 / 1.1 - buy_price / 1.1).toFixed(2)}
                </div>
              </th>
              <th>
                Exc: ${Number(price_5 / 1.1).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(price_5 / 1.1 - buy_price / 1.1).toFixed(2)}
                </div>
              </th>
            </tr>
            <tr className="text-center">
              <th>Inc: ${Number(buy_price).toFixed(2)}</th>
              <th>
                Inc: ${Number(price).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(price - buy_price).toFixed(2)}
                </div>
              </th>
              <th>
                Inc: ${Number(price_1).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(price_1 - buy_price).toFixed(2)}
                </div>
              </th>
              <th>
                Inc: ${Number(price_2).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(price_2 - buy_price).toFixed(2)}
                </div>
              </th>
              <th>
                Inc: ${Number(price_3).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(price_3 - buy_price).toFixed(2)}
                </div>
              </th>
              <th>
                Inc: ${Number(price_4).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(price_4 - buy_price).toFixed(2)}
                </div>
              </th>
              <th>
                Inc: ${Number(price_5).toFixed(2)}
                <div className="text-success">
                  Profit: ${Number(price_5 - buy_price).toFixed(2)}
                </div>
              </th>
            </tr>
          </tbody>
        </Table>
      </>
    )
  }

  return <>{data.product_type == 1 ? <KitPrice /> : <IndividualPrice />}</>
}

export default React.memo(Price);